<template>
  <div class="dashboard">
    <img alt="Analytic-Health-Logo" src="../assets/ah_logo.png" style="height:50px;"/>
    <h1>Dashboard</h1>
    <!-- <div class="alert alert-primary" role="alert">
      A simple primary alert—check it out!
    </div>
    <h1>Example heading <span class="badge badge-secondary">New</span></h1>    
    <button type="button" class="btn btn-primary">Primary</button> -->
    
    <div class="row">
    <div class="column">
    <div class="card" @click="send_alert">
      <h5>Left</h5>
    </div>
    </div>
    <div class="column">
    <div class="card">
      <h5>Right</h5>
    </div>
    </div>
    </div>    

  </div>
</template>

<script>
export default {
  methods: {
    send_alert () {
      console.log('I clicked it!!!')
    }
  }
}
</script>

<style>
.card{
    border-radius: 4px;
    background: #fff;
    box-shadow: 0 6px 10px rgba(0,0,0,.08), 0 0 6px rgba(0,0,0,.05);
      transition: .6s transform cubic-bezier(.155,1.105,.295,1.12),.6s box-shadow,.6s -webkit-transform cubic-bezier(.155,1.105,.295,1.12);
  padding: 14px 80px 18px 36px;
  cursor: pointer;
}

.card:hover{
     transform: scale(1.02);
  box-shadow: 0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06);
}

.row {
  display: flex;
}

.column {
  flex: 50%;
}

@media screen and (max-width: 600px) {
  .column {
    width: 100%;
  }
}
</style>
